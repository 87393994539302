<template>
    <div>
        <PageHeader titleHeader="Sabores" subtitleHeader="Conheça meu trabalho e deguste um sabor inigualável" />
        <div style='margin: 0px 30px 0px 30px'>
            <div class='row'>
                <div class="col-md-4 body-on" v-for="(sabor, index) in sabores" :key="index" :title="sabor.nome">
                    <div class='sabores_item' v-if="sabor.hasImagem">
                        <span class='sabores_item_title'>{{sabor.nome}}</span><hr>
                        <a :id='sabor.id' :href="'./img/sabores/'+sabor.img[0]" :data-lightbox="sabor.grupo" :title="sabor.nome" :data-title="sabor.nome">
                            <b-img-lazy style="border-radius: 10px" :src="'./img/sabores/'+sabor.img[0]" :title="sabor.nome" height="200" width="250" :data-lightbox="sabor.grupo" :data-title="sabor.nome" />
                            <a v-for="(imagem, index2) in sabor.img" :key="index2" style='display: none' :href="'./img/sabores/'+imagem" :data-lightbox="sabor.grupo" :title="sabor.nome" 
                                :data-title="sabor.nome" v-if="index2 > 0">
                                <b-img-lazy :src="'./img/sabores/'+imagem" :title="sabor.nome" height="700" width="900" />
                            </a>
                        </a>
                    </div>
                    <div class='sabores_item' v-else>
                        <span class='sabores_item_title'>{{sabor.nome}}</span><hr>
                        <p style='margin-top: 20%'>Sem fotos</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageHeader from "@/layout/PageHeader";
export default {
  name: "Sabores",
  data() {
    return {
      sabores: require("@/scripts/sabores.js")
    };
  },
  components: {
    PageHeader
  }
};
</script>